import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { mapEdgesToNodes } from "../../lib/helpers";

const ReviewCards = () => {
  const data = useStaticQuery(graphql`
    {
      yelp: file(relativePath: { eq: "reviews/Yelp.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 74)
        }
      }
      google: file(
        relativePath: { eq: "0.0 Repeating Modules/Google icon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 61)
        }
      }
      googleWhite: file(
        relativePath: { eq: "0.0 Repeating Modules/Google white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 61)
        }
      }
      facebook: file(relativePath: { eq: "reviews/Facebook.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 104)
        }
      }
      defaultProfilePicture: file(
        relativePath: { eq: "reviews/default-profile-picture.svg" }
      ) {
        publicURL
      }
      testimonials: allSanityTestimonials(
        sort: { fields: [order], order: ASC }
      ) {
        edges {
          node {
            id
            name
            review
            featured
            platform
            categories {
              title
            }
          }
        }
      }
    }
  `);

  let testimonialsNodes = (data || {}).testimonials
    ? mapEdgesToNodes(data.testimonials).filter((items) => items.featured)
    : [];

  return testimonialsNodes.map((testimonial, i) => {
    return (
      <div
        key={i}
        className={`h-full rounded-tr-6xl rounded-bl-6xl px-10 py-10 pb-12 ${
          testimonial.categories.length
            ? testimonial.categories.slice(0, 1).map((category) => {
                return category.title === "Business"
                  ? "bg-primary-100"
                  : category.title === "Personal"
                  ? "bg-secondary-100"
                  : null;
              })
            : "bg-gray-800"
        }`}
      >
        <blockquote className="">
          <div className="mb-10">
            {testimonial.platform === "Google" &&
            testimonial.categories.length ? (
              testimonial.categories.slice(0, 1).map((category) => {
                return category.title === "Business" ? (
                  <GatsbyImage
                    image={data.google.childImageSharp.gatsbyImageData}
                  />
                ) : category.title === "Personal" ? (
                  <GatsbyImage
                    image={data.google.childImageSharp.gatsbyImageData}
                  />
                ) : null;
              })
            ) : (
              <GatsbyImage
                image={data.googleWhite.childImageSharp.gatsbyImageData}
              />
            )}

            {testimonial.platform === "Yelp" && (
              <GatsbyImage image={data.yelp.childImageSharp.gatsbyImageData} />
            )}

            {testimonial.platform === "Facebook" && (
              <GatsbyImage
                image={data.facebook.childImageSharp.gatsbyImageData}
              />
            )}
          </div>

          <q
            className={`mb-9 block text-xl font-semibold before:hidden ${
              testimonial.categories.length &&
              testimonial.categories.slice(0, 1).map((category) => {
                return category.title === "Business"
                  ? "text-gray-500"
                  : category.title === "Personal"
                  ? "text-gray-500"
                  : null;
              })
            }`}
          >
            {testimonial.review}
          </q>

          <footer>
            <div>
              <cite className="not-italic">
                <div
                  className={`text-sm font-light uppercase tracking-wide ${
                    testimonial.categories.length &&
                    testimonial.categories.slice(0, 1).map((category) => {
                      return category.title === "Business"
                        ? "text-gray-900"
                        : category.title === "Personal"
                        ? "text-gray-900"
                        : null;
                    })
                  }`}
                >
                  {testimonial.name}
                </div>
              </cite>
            </div>
          </footer>
        </blockquote>
      </div>
    );
  });
};

export default ReviewCards;
