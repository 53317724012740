import React from "react";
// import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ReviewCards from "../components/Reviews/ReviewCards";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="DeBlanc + Murphy Reviews | Washington Metro Area CPA Firm"
        description="DeBlanc + Murphy is a highly rated CPA in the greater Washington, DC, area. Read our reviews to see how we provide our clients with exceptional service."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-14 pb-18 md:pt-16 md:pb-24">
        <div className="container">
          <div className="mx-auto max-w-[640px] text-center">
            <h1>In Your Words</h1>
            <p>
              As a highly rated CPA for the greater Washington, DC area,
              providing exceptional service and helping our clients’ achieve
              success means everything to us.
            </p>
            <ButtonSolid href="/review-us/" text="Leave a Review" />
          </div>
        </div>
      </section>

      <section className="pb-20 md:pb-32">
        <div className="container">
          <div className="grid gap-y-8 md:grid-cols-2 md:gap-10 lg:grid-cols-3">
            <ReviewCards />
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

// export const query = graphql`
//   {

//   }
// `;

export default Page;
